
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import HelpPage from '@/components/global/HelpPage.vue';

@Component({
    name: 'CalculatorTopBar',
    components: { HelpPage },
})
export default class CalculatorTopBar extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: false }) private pathBefore!: string;
    @Prop({ required: true }) private pathCurrent!: string;

    @Prop({ required: false }) private showSubmitButton!: boolean;
    @Prop({ required: true }) private disableSubmitButton!: boolean;

    @Prop({ required: false }) private submitButtonText!: string;

    @Prop({ required: false }) private showCancelButton!: boolean;
    @Prop({ required: false }) private cancelButtonText!: string;
    @Prop({ required: false }) private cancelButtonEvent!: EventBusEvents;

    private async submitOnClick() {
        EventBus.$emit(EventBusEvents.calculatorCreateProject, true);
    }

    private cancelOnClick(): void {
        EventBus.$emit(this.cancelButtonEvent);
    }
}
