
import { Component, Vue, Watch } from 'vue-property-decorator';
import CalculatorSidebar from '@/components/views/Calculator/CalculatorSidebar.vue';
import CalculatorMainSection from './CalculatorMainSection.vue';
import CalculatorTopBar from '../components/views/Calculator/CalculatorTopBar.vue';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { Route } from 'vue-router';
import { RouteNames } from '@/enums/routes/RouteNames';

@Component({
  name: 'Calculator',
  components: { CalculatorSidebar, CalculatorMainSection, CalculatorTopBar },
})
export default class Calculator extends Vue {
  private clearAllEvent: EventBusEvents = EventBusEvents.clearCalculator;
  private calculatorSidebar!: CalculatorSidebar;

  private hasProductView: boolean = false;

  @Watch('$route', { immediate: true, deep: true })
  private onRouteChange(newVal: Route) {
    this.hasProductView = newVal.name === RouteNames.calculatorProduct;
  }
}
