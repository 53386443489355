
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AppSidebar from '@/components/global/sidebar/AppSidebar.vue';
import { UserRepository } from '@/repositories/UserRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { RouteNames } from '@/enums/routes/RouteNames';
import AutocompleteGroupInput from '@/components/global/AutocompleteGroupInput.vue';
import ProjectRepository from '@/repositories/ProjectRepository';
import { AxiosResponse } from 'axios';
import ProjectModel from '@/models/Project';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import ConfiguratorService from '@/services/ConfiguratorService';
import OfferItem from '@/models/OfferItem';
import { JsonAPIError } from '@/interfaces/general/JsonAPIError';
import { formatFormData } from '@/helpers/FormDataHelper';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import Montage from '@/models/Montage';
import PurchasePriceManipulation from '@/models/PurchasePriceManipulation';
import Offer from '@/models/Offer';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { ErrorOptions } from '@/interfaces/ErrorOptions';

@Component({
    name: 'CalculatorSidebar',
    components: { AppSidebar, AutocompleteGroupInput },
})
export default class CalculatorSidebar extends Vue {
    public $refs!: {
        clientsInput: AutocompleteGroupInput;
    };
    public projectId: string = '';

    private production!: boolean;
    private connection!: number | null;
    private checklistFieldErrorsExist: boolean = false;
    private paymentTypeId!: string | null;
    private productFormValues!: ActiveProductFormValueObject | null;
    private productFormId!: number | null;

    private price: number = 0.0;
    private tax: number = 0.0;
    private taxValue: number = this.tax;
    private discount: number = 5;
    private discounts: Array<{ amount: number; type: string; label: string }> = [];
    private montage: number = 0.0;
    private montageValue: number = this.montage;
    private salesPrice: number = 0.0;
    private finalPrice: number = 0.0;
    private modalVisible: boolean = false;
    private typeOfDiscount: string = 'percentage';
    private editableTax: boolean = false;
    private form: any = this.$form.createForm(this, { name: 'coordinated' });
    private defaultClientId: string = '';
    private selectedClientId: string | null = null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private formFieldErrorsExist = false;
    private eventFinishedCallback: (() => void) | null = null;
    private eventFinished: boolean = false;

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private async created() {
        EventBus.$on(EventBusEvents.calculatorPurchasePrice, this.onPriceRecalcualted);

        EventBus.$on(EventBusEvents.calculatorProductData, this.updateCalculatorProductValues);

        EventBus.$on(EventBusEvents.calculatorCreateProject, this.createNewProject);

        EventBus.$on(EventBusEvents.clearCalculator, this.clearAll);
        EventBus.$on(EventBusEvents.formValidationFinished, this.continueExecution);
    }

    private get user() {
        return UserRepository.getCurrentUser();
    }

    private updateCalculatorProductValues({
        productFormValues,
        connection,
        production,
        checklistFieldErrorsExist,
        paymentTypeId,
        productFormId,
    }: any) {
        this.productFormValues = productFormValues;
        this.connection = connection;
        this.production = production;
        this.checklistFieldErrorsExist = checklistFieldErrorsExist;
        this.paymentTypeId = paymentTypeId;
        this.productFormId = productFormId;
    }

    private clearAll() {
        this.price = 0.0;
        this.salesPrice = 0.0;
        this.tax = 0.0;
        this.discounts = [];
        this.montage = 0.0;
        this.selectedClientId = null;
        if (this.$route.name !== RouteNames.calculator) {
            this.$router.push({
                name: RouteNames.calculator,
            });
        }
    }

    private onPriceRecalcualted(prices: any) {
        if (prices.length === 0) {
            this.price = 0;
            this.finalPrice = 0;
            this.salesPrice = 0.0;
            this.$refs.clientsInput.clearInput();
            this.selectedClientId = null;
            this.defaultClientId = '';
        } else {
            const key = 'priceValue';
            this.price = prices.purchasePrices[prices.purchasePrices.length - 1][key];
            this.salesPrice = prices.sellingPrices[prices.sellingPrices.length - 1][key];
            this.calculateFinalPrice();
        }
    }

    private onClientSelect(clientId: string) {
        this.selectedClientId = clientId;

        EventBus.$emit(EventBusEvents.calculatorClientChosen, this.selectedClientId);
    }

    private showModal() {
        this.modalVisible = true;
    }

    private calculateFinalPrice() {
        // (price + discount) + montage + taxes
        let discountValue;
        this.finalPrice = this.salesPrice;
        this.discounts.forEach((value) => {
            if (value.type === 'percentage') {
                discountValue = (this.salesPrice * value.amount) / 100;
                this.finalPrice = this.finalPrice - discountValue;
            }
            if (value.type === 'money') {
                this.finalPrice = this.finalPrice - value.amount;
            }
        });
        this.finalPrice = this.finalPrice + this.montage;
        this.finalPrice = this.finalPrice + (this.finalPrice * this.tax) / 100;
    }

    private handleSubmit(e: any) {
        this.discounts.push({
            amount: this.discount,
            type: this.typeOfDiscount,
            label:
                this.typeOfDiscount === 'percentage'
                    ? this.$t('Discount') + ` ${this.discount}%`
                    : this.$t('Discount') + ` ${this.discount} ${this.currencySymbol}`,
        });
        this.modalVisible = false;
        this.calculateFinalPrice();
    }

    private async createNewProject() {
        this.loadingOverlay.start();
        this.eventFinishedCallback = null;
        EventBus.$emit(EventBusEvents.validateFormFields);

        await new Promise<void>((resolve) => {
            this.eventFinishedCallback = resolve;

            if (this.eventFinished) {
                this.eventFinished = false;
                resolve();
            }
        });

        if (this.formFieldErrorsExist) {
            this.loadingOverlay.stop();
            return;
        }

        const response = (await ProjectModel.createNew(this.selectedClientId!)) as AxiosResponse;
        const project = ProjectRepository.getProjectById(response.data.id);

        if (!project) {
            throw new Error('There was an error while creation a new project.');
        }

        this.projectId = project.id;
        this.addProductToOffer();
    }

    private async addProductToOffer() {
        const projectId = this.projectId as string;
        const routeOfferId = this.$route.query.offerId as string;
        let validOfferId;

        try {
            validOfferId = await ConfiguratorService.getValidOfferId(projectId, routeOfferId, this.paymentTypeId);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loadingOverlay.stop();
            return;
        }

        const formData: Array<{ id: string; value: ConfiguratorValue }> = formatFormData(
            this.productFormValues as ActiveProductFormValueObject
        );

        try {
            // tslint:disable-next-line:max-line-length
            await OfferItem.createNew(
                formData,
                String(validOfferId),
                this.checklistFieldErrorsExist,
                this.connection,
                this.production,
                null,
                this.productFormId
            );
        } catch (e) {
            const errorObj = e as ErrorOptions;

            errorObj.response?.data?.errors?.forEach((error: JsonAPIError) => {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: error.title ? error.title : errorObj.message,
                });
            });
            this.loadingOverlay.stop();
            return;
        }

        // Tax
        Offer.updateTaxAndExpirationDateAndConfigurationOverride(
            validOfferId,
            this.tax,
            null,
            '',
            undefined,
            this.paymentTypeId
        );

        // Add montage
        if (this.montage !== 0) {
            await Montage.createNewAndSaveLocally(
                {
                    delivery: false,
                    assembly: true,
                    price: this.montage,
                    numberOfWorkers: 0,
                    expectedTime: 0,
                    electrician: false,
                    deconstruction: false,
                    removal: false,
                },
                validOfferId
            );
        }

        // Discounts
        let type = '1';
        for (const d of this.discounts) {
            if (d.type === 'money' && d.amount < 0) {
                type = '1';
            } else if (d.type === 'percentage' && d.amount < 0) {
                type = '2';
            } else if (d.type === 'money' && d.amount > 0) {
                type = '3';
            } else if (d.type === 'percentage' && d.amount > 0) {
                type = '4';
            }

            await PurchasePriceManipulation.createNew({
                productCategory: '0',
                priceManipulationType: type,
                name: '',
                value: d.amount,
                offer: validOfferId,
            });
        }

        EventBus.$emit(EventBusEvents.changesInDataMade, { state: false });

        this.$router.push({
            name: RouteNames.project,
            params: { id: projectId },
            query: {
                offerId: validOfferId,
                initialTab: `${ProjectTabValues.Products}`,
            },
        });
        this.loadingOverlay.stop();
    }

    private deleteDiscount(e: any) {
        if (e > -1) {
            this.discounts.splice(e, 1);
        }
        this.calculateFinalPrice();
    }

    private handleDiscountTypeChange(value: any) {
        if (typeof value !== 'string') {
            this.typeOfDiscount = value.key;
        } else {
            this.typeOfDiscount = value;
        }
    }

    private roundToTwo(num: number) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    private handleDiscount(value: number) {
        this.discount = value;
    }
    private handleCancel(e: any) {
        this.modalVisible = false;
    }

    private editTax() {
        this.editableTax = true;
    }

    private handleTax(e: any) {
        this.taxValue = e;
    }
    private handleMontage(e: any) {
        this.montageValue = e;
    }
    private cancelTax() {
        this.taxValue = 0.0;
        this.montageValue = 0.0;
        this.editableTax = false;
    }
    private saveTax() {
        this.tax = this.taxValue;
        this.montage = this.montageValue;
        this.editableTax = false;
        this.calculateFinalPrice();
    }

    private continueExecution(formFieldErrorsExist: boolean) {
        this.formFieldErrorsExist = formFieldErrorsExist;

        if (this.eventFinishedCallback) {
            this.eventFinishedCallback();
        } else {
            this.eventFinished = true;
        }

        this.eventFinishedCallback = null;
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.calculatorPurchasePrice, this.onPriceRecalcualted);
        EventBus.$off(EventBusEvents.clearCalculator, this.clearAll);
        EventBus.$off(EventBusEvents.calculatorProductData, this.updateCalculatorProductValues);
        EventBus.$off(EventBusEvents.calculatorCreateProject, this.createNewProject);
        EventBus.$off(EventBusEvents.formValidationFinished, this.continueExecution);
    }

    @Watch('$route')
    private async onRouteChange() {
        this.onPriceRecalcualted([]);
    }
}
